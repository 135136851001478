import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { array, bool, func, number, shape, string } from 'prop-types';

import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import config from '../../config';
import { propTypes } from '../../util/types';
import { USER_ROLE_PARTNER } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import { withViewport } from '../../util/contextHelpers';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';

import logo from './../Logo/Logo_new_transparent.png';

import {
  Button,
  LimitedAccessBanner,
  Modal,
  ModalMissingInformation,
  NamedLink,
  TawkTo,
  TopbarDesktop,
  TopbarMobileMenu,
} from '../../components';

import { TopbarSearchForm } from '../../forms';
import { updateConnectedUsers } from '../../ducks/user.duck';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';

import css from './Topbar.module.css';
import { getSocket } from '../../socket/socketConfig';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connectedUsers: [],
      shouldFetch: true,
      isMobileMenuOpen: false
    }
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  // componentDidMount() {
  //   const { onUpdateConnectedUsers } = this.props;
  
  //   // Generate a unique ID for this tab
  //   if (!sessionStorage.getItem('tabId')) {
  //     sessionStorage.setItem('tabId', `${Date.now()}-${Math.random()}`);
  //   }
  
  //   const socket = getSocket();
  //   if (socket) {
  //     socket.on('connectedUsers', ({ users }) => {
  //       onUpdateConnectedUsers(users);
  //     });
  //   }
  
  //   // Handle disconnect for this tab
  //   window.addEventListener('beforeunload', () => {
  //     if (socket) {
  //       socket.emit('leave', { tabId: sessionStorage.getItem('tabId') });
  //     }
  //   });
  // }

  // componentDidUpdate() {
  //   const socket = getSocket();
  //   const { currentUser, connectedUsers } = this.props;

  //   if (socket) {
  //     // Emit join event with tabId
  //     if (currentUser && currentUser.id) {
  //       if ((!connectedUsers || !Object.keys(connectedUsers).length) || (connectedUsers && !connectedUsers[currentUser.id.uuid])) {
  //         socket.emit('join', {
  //           userId: currentUser.id.uuid,
  //           email: currentUser.attributes?.email || currentUser.attributes?.profile?.publicData?.email,
  //           tabId: sessionStorage.getItem('tabId'), // Include tab ID in the payload
  //         });
  //       }
  //     }
  //   }
  // }

  handleMobileMenuOpen() {
    // redirectToURLWithModalState(this.props, 'mobilemenu');
    this.setState({ isMobileMenuOpen: true })
  }

  handleMobileMenuClose() {
    // redirectToURLWithoutModalState(this.props, 'mobilemenu');
    this.setState({ isMobileMenuOpen: false })
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const keywords = values.keywords;
    const { history } = this.props;
    const searchParams = {
      ...currentSearchParams,
      keywords,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const socket = getSocket();
    const { onLogout, history, currentUser } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if(!socket) return
      if (config.dev) {
        socket.emit('disconnected', { userId: currentUser?.id?.uuid });
        history.push(path);
      } else if (typeof window !== 'undefined') {
        socket.emit('disconnected', { userId: currentUser?.id?.uuid });
        window.location = path;
      }
      // console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      typePartner,
      currentUserHasListings,
      currentUserListing,
      currentUserBriefs,
      currentUserListingFetched,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      showRoleSwitcher,
      connectedUsers,
      onUpdateConnectedUsers,
    } = this.props;
    // const typeUser = getUserRole(currentUser);
    // const createdAt = !!currentUser?.id && currentUser?.attributes?.createdAt;
    // const offerEndDate = moment(createdAt).isBefore('2023/03/31');

    const { mobilemenu, mobilesearch, keywords } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    // const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        intl={intl}
        typePartner={typePartner}
        showRoleSwitcher={showRoleSwitcher}
        location={location}
      />
    );

    // Only render current search if full place object is available in the URL params
    // const locationFieldsPresent = config.sortSearchByDistance
    //   ? address && origin && bounds
    //   : address && bounds;
    const initialSearchFormValues = {
      keywords: keywords
    };

    const searchPlaceholder =
      typePartner === USER_ROLE_PARTNER
        ? intl.formatMessage({ id: 'TopbarSearchForm.placeholderBriefs' })
        : intl.formatMessage({ id: 'TopbarSearchForm.placeholderExpert' });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
          connectedUsers={connectedUsers}
          onUpdateConnectedUsers={onUpdateConnectedUsers}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
          <NamedLink
            className={css.home}
            name="LandingPage"
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
          >
            <img src={logo} alt='/' className={css.mobileIcon} />
            {/*<Logo format="mobile" />*/}
          </NamedLink>
          <Button
            rootClassName={css.searchMenu}
            onClick={this.handleMobileSearchOpen}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            <SearchIcon className={css.searchMenuIcon} />
          </Button>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUserListing={currentUserListing}
            currentUserBriefs={currentUserBriefs}
            currentUserListingFetched={currentUserListingFetched}
            currentUser={currentUser}
            typePartner={typePartner}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            history={this.props}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            showRoleSwitcher={showRoleSwitcher}
            windowWidth={viewport.width}
            location={location}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={this.state.isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              placeholder={searchPlaceholder}
              initialValues={initialSearchFormValues}
              className={css.inputSearchStyle}
              isMobile
              typeUser={typePartner}
            />
            {/*<p className={css.mobileHelp}>*/}
            {/*  <FormattedMessage id="Topbar.mobileSearchHelp" />*/}
            {/*</p>*/}
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />
        <TawkTo widgetId={process.env.REACT_APP_TAWK_PROPERTY_AND_WIDGET_ID}  currentUser={currentUser}/>
        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const {

  } = state.SearchPage;
  const { isAuthenticated } = state.Auth;
  const { connectedUsers } = state.user;
  return {
    // listings: typePartner == 'partner' ? filteredListings : pageListings,
    connectedUsers,
    isAuthenticated
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateConnectedUsers: params => dispatch(updateConnectedUsers(params)),
});
const Topbar = compose(
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
