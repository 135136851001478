import React, { useRef, useState } from 'react';
import { AskAndChatSectionForm, SendMessageForm } from '../../../forms';
import { onMessageSubmit } from './helperFunction';
import {
  CUSTOMER,
  OFFLINE_STATUS,
  ONLINE_STATUS,
  PROVIDER,
} from '../../../util/types';
import css from '../GigAppTransactionPanel.module.css';
import {
  getCurrentAuthor,
  getUserDetails,
  getUserDisplayName,
} from '../../../util/destructorHelpers';
import ActivityFeed from '../../ActivityFeed/ActivityFeed';
import { useDispatch } from 'react-redux';
import { ensureUser } from '../../../util/data';
import { isMobileSafari } from '../../../util/userAgent';

const EVERYONE = '@everyone';
const ONLINE = '@here';
const OFFLINE = '@nothere';

const AskAndChatSection = props => {
  const {
    currentTransaction,
    intl,
    customer,
    provider,
    currentUser,
    collaborationData = [],
    connectedUsers,
    onManageDisableScrolling,
    boxFolder,
    history,
    messages,
    fetchMessagesInProgress,
    activeTab,
    isOnline
  } = props;

  const isMobSaf = isMobileSafari();
  const chainedTransactionId = currentTransaction?.id?.uuid;
  const currentUserEmail = currentUser?.id && getUserDetails(currentUser)?.email;
  const currentCustomer = currentTransaction?.customer || {};
  const currentProvider = currentTransaction?.provider || {};
  const currentAuthor = getCurrentAuthor({ currentTransaction, currentUser });
  const ensuredAuthor = ensureUser(currentAuthor);
  const customerName = !!currentCustomer?.id && getUserDetails(currentCustomer)?.fullName;
  const customerEmail = !!currentCustomer.id && getUserDetails(currentCustomer)?.email;
  const providerName = !!currentProvider.id && getUserDetails(currentProvider)?.fullName;
  const providerEmail = !!currentProvider.id && getUserDetails(currentProvider)?.email;
  const userName =
    currentProvider?.id?.uuid === currentUser?.id?.uuid
      ? customerName
      : currentCustomer?.id?.uuid === currentUser?.id?.uuid
      ? providerName
      : null;
  const currentPath = typeof window !== 'undefined' && window.location.href;

  const authorName = ensuredAuthor?.id && getUserDetails(ensuredAuthor)?.fullName;
  const authorEmail = ensuredAuthor?.id && ensuredAuthor?.attributes?.profile?.publicData?.email;
  const [sendMessageFormFocused, setSendMessageFormFocused] = useState(false);
  const divRef = useRef();
  const dispatch = useDispatch();
  const sendMessagePlaceholder = intl.formatMessage({
    id: 'TransactionPanel.sendMessagePlaceholder',
  });
  const membersData = [
    {
      ...customer,
      name: getUserDisplayName(customer?.fullName),
      fullName: customer?.fullName,
      role: CUSTOMER,
    },
    {
      ...provider,
      name: getUserDisplayName(provider?.fullName),
      fullName: provider?.fullName,
      role: PROVIDER,
    },
    // ...collaborationData,
  ];
  const isCustomer = customer?.id === currentUser?.id?.uuid;
  const isProvider = provider?.id === currentUser?.id?.uuid;


  const onSendMessageFormFocus = () => {
    setSendMessageFormFocused(true);
    // Scroll to bottom
    if (isMobSaf) {
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  };

  const onSendMessageFormBlur = () => {
    setSendMessageFormFocused(false);
  };
  return (
    <div className={css.askAndChatSection}>
      <ActivityFeed
        className={css.feed}
        messages={messages}
        history={history}
        transaction={currentTransaction}
        currentUser={currentUser}
        hasOlderMessages={false}
        onOpenReviewModal={false}
        onShowOlderMessages={null}
        fetchMessagesInProgress={fetchMessagesInProgress}
        lastMessageRef={divRef}
        connectedUsers={connectedUsers}
        isOnline={isOnline}
        isCustomer={isCustomer}
        isProvider={isProvider}
        activeTab={activeTab}
        isJobListing={false}
        onManageDisableScrolling={onManageDisableScrolling}
        boxFolder={boxFolder}
      />
      <div className={css.chatContainerBlock}>
        <SendMessageForm
          formId={'TransactionPanel.SendMessageForm'}
          rootClassName={css.sendMessageForm}
          messagePlaceholder={sendMessagePlaceholder}
          inProgress={false}
          sendMessageError={null}
          onFocus={onSendMessageFormFocus}
          onBlur={onSendMessageFormBlur}
          onSubmit={(values, form) =>
            onMessageSubmit({
              values,
              form,
              currentUserEmail,
              currentTransaction,
              chainedTransactionId,
              currentUser,
              dispatch,
              authorName,
              authorEmail,
              userName,
              currentPath,
              connectedUsers,
            })
          }
          setClearForm={{}}
          currentTransaction={currentTransaction}
          isReviewd={false}
          isChatExpired={false}
          // sendGif={onMessageSubmit}
          membersData={membersData}
          nonMemberData={[
            { fullName: EVERYONE, email: 'Notify all members of this team' },
            { fullName: ONLINE, email: 'Notify all online members of this team' },
            { fullName: OFFLINE, email: 'Notify all offline members of this team' },
          ]}
        />
      </div>
    </div>
  );
};

export default AskAndChatSection;
