import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
  // txIsEnquired,
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
  // TRANSITION_ACCEPT,
  // TRANSITION_DECLINE,
  TRANSITION_DECLINE_BRIEF,
  TRANSITION_CREATE_PROPOSAL,
  TRANSITION_DECLINE_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
  TRANSITION_BRIEF_DECLINE_PROPOSAL,
  TRANSITION_BRIEF_ACCEPT_PROPOSAL,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITIONS,
  TRANSITION_REQUEST_MARK_COMPLETE,
  TRANSITION_BRIEF_UPDATE_PROPOSAL,
  TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR,
  getTransition,
  TRANSITION_OFFLINE_PAYMENT_REQUESTED,
  TRANSITION_FINAL_PAYMENT_COMPLETED,
  TRANSITION_ACCEPT_JOB_APPLICATION,
  TRANSITION_ACCEPT_JOB_APPLICATION_AFTER_JOB,
  TRANSITION_REQUEST_SECOND_INTERVIEW,
  TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
  TRANSITION_BRIEF_CREATE_PROPOSAL,
  TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR,
  TRANSITION_REQUEST_CONTRACT_SIGNATURE,
  TRANSITION_CSM_PRIVATE_BRIEF_INVITE,
  TRANSITION_ACCEPT_PROPOSAL_OPERATOR,
  TRANSITION_PROPOSAL_DECLINE_CSM_INVITE_OPERATOR,
  TRANSITION_CONFIRM_FIRST_INTERVIEW,
  TRANSITION_ACCEPT_JOB_OFFER,
  TRANSITION_DECLINE_JOB_OFFER,
  TRANSITION_CONFIRM_SECOND_INTERVIEW,
  TRANSITION_COMFIRM_CONTRACT_SIGNATURE,
  TRANSITION_REQUEST_OFFLINE_PAYMENT,
  TRANSITION_DECLINE_JOB_DESCRIPTION,
  TRANSITION_DECLINE_SECOND_INTERVIEW,
  TRANSITION_DECLINE_FIRST_INTERVIEW,
  TRANSITION_SUBMIT_CASE_STUDY,
} from '../../util/transaction';
import {
  makeRequestToCustomEndpoint,
  transactionLineItems,
  transitionPrivileged,
  initiatePrivileged,
  updateMetadataRequest,
  updateMetadata,
  fetchFirmMember,
  getTransaction,
  createZoomToken,
  getZakCode,
  createMeeting,
  createUserSignature,
  updateUserProfile,
  updateMilestone,
  completeProposalThroughIsdk,
  fetchMessage,
  showAndUpdateCollaboratorProfile,
  getUserData,
  getZohoAccessToken,
  sendDocument,
  fetchDocument,
  createDocument,
  sendReminder,
  updateMetadataThroughIsdk,
  updateListingPublicData,
  fetchTemplate,
  deleteTemplate,
  updateFirmUserDetails,
  createFolder,
  scheduleGoogleMeeting,
  isdkTransactionsTransition,
  apiBaseUrl,
  sendEmailUsingZepto,
  getAndDeleteNotifications,
  storeNotification,
  fetchTransactionChatMessages,
} from '../../util/api';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { findNextBoundary, nextMonthFn, monthIdStringInTimeZone } from '../../util/dates';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchPartnerProfile } from '../../ducks/user.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import moment from 'moment';
import {
  ADD_MILESTONE,
  DELETE_MILESTONE,
  EDIT_MILESTONE,
  JOB_NOTIFICATION,
  MILESTONE_NOTIFICATION,
  OFFLINE,
  ONLINE,
  PROJECT_UNDERWAY,
  REMOVE_FIRM_MEMBER_FROM_TRANSACTION,
  SEND_PRIVATE_PROPOSAL,
  TRANSACTION_APPLY_JOB_PROCESS,
  TRANSACTION_BRIEF_PROCESS,
  TRANSACTION_JOB_DESCRIPTION_PROCESS,
  TRANSACTION_NOTIFICATION,
  TRANSACTION_PROPOSAL_PROCESS,
  USER_ROLE_CLIENT,
} from '../../util/types';
import { COMPLETE_MILESTONE } from '../../util/types';
import { generateNotificationContent, getUserDetails } from '../../util/destructorHelpers';
import {
  createMeetingError,
  createMeetingRequest,
  createMeetingSuccess,
} from '../ListingPage/ListingPage.duck';
import axios from 'axios';
import { INVITE_STATUS_ACTIVE } from '../../util/types';
import { getUserRole } from '../../util/userRole';

const { UUID } = sdkTypes;
const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';
export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';
export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';
export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';
export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';
export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';
export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';
export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';
export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';
export const FETCH_LINE_ITEMS_REQUEST = 'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';
export const FETCH_CHAINED_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_CHAINED_MESSAGES_REQUEST';
export const FETCH_CHAINED_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_CHAINED_MESSAGES_SUCCESS';
export const FETCH_CHAINED_MESSAGES_ERROR = 'app/TransactionPage/FETCH_CHAINED_MESSAGES_ERROR';
export const FETCH_CHAINED_TRANSACTION_REQUEST =
  'app/TransactionPage/FETCH_CHAINED_TRANSACTION_REQUEST';
export const FETCH_CHAINED_TRANSACTION_SUCCESS =
  'app/TransactionPage/FETCH_CHAINED_TRANSACTION_SUCCESS';
export const FETCH_CHAINED_TRANSACTION_ERROR =
  'app/TransactionPage/FETCH_CHAINED_TRANSACTION_ERROR';
export const ACTION_REQUEST = 'app/TransactionPage/ACTION_REQUEST';
export const ACTION_SUCCESS = 'app/TransactionPage/ACTION_SUCCESS';
export const ACTION_ERROR = 'app/TransactionPage/ACTION_ERROR';

export const SHOW_FIRM_LISTING_REQUEST = 'app/TransactionPage/SHOW_FIRM_LISTING_REQUEST';
export const SHOW_FIRM_LISTING_SUCCESS = 'app/TransactionPage/SHOW_FIRM_LISTING_SUCCESS';
export const SHOW_FIRM_LISTING_ERROR = 'app/TransactionPage/SHOW_FIRM_LISTING_ERROR';

export const COLLABORATION_INVITE_REQUEST = 'app/TransactionPage/COLLABORATION_INVITE_REQUEST';
export const COLLABORATION_INVITE_SUCCESS = 'app/TransactionPage/COLLABORATION_INVITE_SUCCESS';
export const COLLABORATION_INVITE_ERROR = 'app/TransactionPage/COLLABORATION_INVITE_ERROR';
export const COLLABORATION_INVITE_CLEAR_SUCCESS_TOASTER =
  'app/TransactionPage/COLLABORATION_INVITE_CLEAR_SUCCESS_TOASTER';

export const ZOOM_MEETING_REQUEST = 'app/TransactionPage/ZOOM_MEETING_REQUEST';
export const ZOOM_MEETING_SUCCESS = 'app/TransactionPage/ZOOM_MEETING_SUCCESS';
export const ZOOM_MEETING_ERROR = 'app/TransactionPage/ZOOM_MEETING_ERROR';
export const ZOOM_MEETING_CREATED = 'app/TransactionPage/ZOOM_MEETING_CREATED';

export const UPDATE_FINAL_PAYMENT_REQUEST = 'app/TransactionPage/UPDATE_FINAL_PAYMENT_REQUEST';
export const UPDATE_FINAL_PAYMENT_SUCCESS = 'app/TransactionPage/UPDATE_FINAL_PAYMENT_SUCCESS';

export const FETCH_LAST_TRANSITION_REQUEST = 'app/TransactionPage/FETCH_LAST_TRANSITION_REQUEST';
export const FETCH_LAST_TRANSITION_SUCCESS = 'app/TransactionPage/FETCH_LAST_TRANSITION_SUCCESS';

export const QUERY_REVIEWS_SUCCESS = 'app/TransactionPage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/TransactionPage/QUERY_REVIEWS_ERROR';

export const SEND_CSM_INVITE_TO_FIRM_MEMBER_REQUEST =
  'app/TransactionPage/SEND_CSM_INVITE_TO_FIRM_MEMBER_REQUEST';
export const SEND_CSM_INVITE_TO_FIRM_MEMBER_SUCCESS =
  'app/TransactionPage/SEND_CSM_INVITE_TO_FIRM_MEMBER_SUCCESS';
export const SEND_CSM_INVITE_TO_FIRM_MEMBER_ERROR =
  'app/TransactionPage/SEND_CSM_INVITE_TO_FIRM_MEMBER_ERROR';

export const NOTIFICATION_REQUEST = 'app/TransactionPage/NOTIFICATION_REQUEST';
export const NOTIFICATION_SUCCESS = 'app/TransactionPage/NOTIFICATION_SUCCESS';
export const NOTIFICATION_ERROR = 'app/TransactionPage/NOTIFICATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,

  chainedMessagesInProgress: false,
  chainedMessagesError: null,
  chainedMessages: [],

  chainedTransaction: null,
  chainedTransactionInProgress: false,
  chainedTransactionError: null,

  actionInProgress: false,
  actionError: null,
  documentsBrief: [],

  transactionDocuments: [],
  firmId: null,
  showFirmListingInProgress: false,
  showFirmListingError: null,
  sendInviteInProgress: false,
  sendInviteError: null,
  zoomMeetingInProgress: false,
  zoomMeetingError: false,
  zoomMeetingCreated: false,
  lastTransition: null,
  updateFinalPaymentDetailsInProgress: false,
  sendCsmInviteInProgress: false,
  sendCsmInviteError: null,
  notifications: [],
  notificationInProgress: false,
  notificationError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case FETCH_CHAINED_MESSAGES_REQUEST:
      return { ...state, chainedMessagesInProgress: true, chainedMessagesError: null };
    case FETCH_CHAINED_MESSAGES_SUCCESS:
      return { ...state, chainedMessagesInProgress: false, chainedMessages: payload };
    case FETCH_CHAINED_MESSAGES_ERROR:
      return { ...state, chainedMessagesInProgress: false, chainedMessagesError: payload };

    case FETCH_CHAINED_TRANSACTION_REQUEST:
      return { ...state, chainedTransactionInProgress: true, chainedTransactionError: null };
    case FETCH_CHAINED_TRANSACTION_SUCCESS:
      return { ...state, chainedTransactionInProgress: false, chainedTransaction: payload };
    case FETCH_CHAINED_TRANSACTION_ERROR:
      return { ...state, chainedTransactionInProgress: false, chainedTransactionError: payload };

    case ACTION_REQUEST:
      return { ...state, actionInProgress: true, actionError: null };
    case ACTION_SUCCESS:
      return { ...state, actionInProgress: false };
    case ACTION_ERROR:
      return { ...state, actionInProgress: false, actionError: payload };
    case SHOW_FIRM_LISTING_REQUEST:
      return { ...state, showFirmListingInProgress: true, showFirmListingError: null };
    case SHOW_FIRM_LISTING_SUCCESS:
      return {
        ...state,
        firmId: payload.id,
        showFirmListingInProgress: false,
        showFirmListingError: null,
      };
    case SHOW_FIRM_LISTING_ERROR:
      return { ...state, showFirmListingInProgress: false, showFirmListingError: payload };
    case COLLABORATION_INVITE_REQUEST:
      return { ...state, sendInviteInProgress: true, sendInviteError: null };
    case COLLABORATION_INVITE_SUCCESS:
      return {
        ...state,
        sendInviteInProgress: false,
        invitationSent: true,
        sendInviteError: null,
      };
    case COLLABORATION_INVITE_ERROR:
      return { ...state, sendInviteInProgress: false, sendInviteError: payload };
    case COLLABORATION_INVITE_CLEAR_SUCCESS_TOASTER:
      return {
        ...state,
        sendInviteInProgress: false,
        invitationSent: false,
        sendInviteError: null,
      };
    case ZOOM_MEETING_REQUEST:
      return { ...state, zoomMeetingInProgress: true, zoomMeetingError: null };
    case ZOOM_MEETING_SUCCESS:
      return { ...state, zoomMeetingInProgress: false, zoomMeetingError: null };
    case ZOOM_MEETING_ERROR:
      return { ...state, zoomMeetingInProgress: false, zoomMeetingError: true };
    case ZOOM_MEETING_CREATED:
      return {
        ...state,
        zoomMeetingInProgress: false,
        zoomMeetingError: false,
        zoomMeetingCreated: true,
      };
    case UPDATE_FINAL_PAYMENT_REQUEST:
      return {
        ...state,
        updateFinalPaymentDetailsInProgress: true,
      };
    case UPDATE_FINAL_PAYMENT_SUCCESS:
      return {
        ...state,
        updateFinalPaymentDetailsInProgress: false,
      };
    case FETCH_LAST_TRANSITION_REQUEST:
      return { ...state, lastTransition: payload };
    case FETCH_LAST_TRANSITION_SUCCESS:
      return { ...state, lastTransition: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case SEND_CSM_INVITE_TO_FIRM_MEMBER_REQUEST:
      return { ...state, sendCsmInviteInProgress: true, sendCsmInviteError: null };
    case SEND_CSM_INVITE_TO_FIRM_MEMBER_SUCCESS:
      return { ...state, sendCsmInviteInProgress: false, sendCsmInviteError: null };
    case SEND_CSM_INVITE_TO_FIRM_MEMBER_ERROR:
      return { ...state, sendCsmInviteInProgress: false, sendCsmInviteError: payload };
    case NOTIFICATION_REQUEST:
      return { ...state, notificationInProgress: true, notificationError: null };
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationInProgress: false,
        notificationError: null,
        notifications: payload,
      };
    case NOTIFICATION_ERROR:
      return { ...state, notificationInProgress: false, notificationError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const updateFinalPaymentDetailsRequest = () => ({ type: UPDATE_FINAL_PAYMENT_REQUEST });
const updateFinalPaymentDetailsSuccess = () => ({ type: UPDATE_FINAL_PAYMENT_SUCCESS });
const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const zoomMeetingRequest = () => ({ type: ZOOM_MEETING_REQUEST });
const zoomMeetingSuccess = () => ({ type: ZOOM_MEETING_SUCCESS });
const zoomMeetingError = () => ({ type: ZOOM_MEETING_ERROR });
const zoomMeetingCreated = () => ({ type: ZOOM_MEETING_CREATED });

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

const fetchChainedMessagesRequest = () => ({ type: FETCH_CHAINED_MESSAGES_REQUEST });
const fetchChainedMessagesSuccess = payload => ({
  type: FETCH_CHAINED_MESSAGES_SUCCESS,
  payload,
});
const fetchChainedMessagesError = error => ({
  type: FETCH_CHAINED_MESSAGES_ERROR,
  error: true,
  payload: error,
});

const fetchChainedTransactionRequest = () => ({ type: FETCH_CHAINED_TRANSACTION_REQUEST });
const fetchChainedTransactionSuccess = payload => ({
  type: FETCH_CHAINED_TRANSACTION_SUCCESS,
  payload,
});
const fetchChainedTransactionError = error => ({
  type: FETCH_CHAINED_TRANSACTION_ERROR,
  error: true,
  payload: error,
});

export const actionInProgress = () => ({ type: ACTION_REQUEST });
export const actionSuccess = () => ({ type: ACTION_SUCCESS });

const actionError = error => ({
  type: ACTION_ERROR,
  error: true,
  payload: error,
});

export const showFirmListingRequest = () => ({
  type: SHOW_FIRM_LISTING_REQUEST,
});
export const showFirmListingSuccess = id => ({
  type: SHOW_FIRM_LISTING_SUCCESS,
  payload: { id },
});

export const showFirmListingError = e => ({
  type: SHOW_FIRM_LISTING_ERROR,
  error: true,
  payload: e,
});

export const collaborationInviteRequest = () => ({
  type: COLLABORATION_INVITE_REQUEST,
});
export const collaborationInviteSuccess = () => ({
  type: COLLABORATION_INVITE_SUCCESS,
});
export const collaborationInviteClearSuccessToaster = () => ({
  type: COLLABORATION_INVITE_CLEAR_SUCCESS_TOASTER,
});
export const collaborationInviteError = e => ({
  type: COLLABORATION_INVITE_ERROR,
  error: true,
  payload: e,
});
export const fetchLastTransitionRequest = data => ({
  type: FETCH_LAST_TRANSITION_REQUEST,
  payload: data,
});
export const fetchLastTransitionSuccess = () => ({
  type: FETCH_LAST_TRANSITION_SUCCESS,
});
export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});
export const sendCsmInviteRequest = () => ({
  type: SEND_CSM_INVITE_TO_FIRM_MEMBER_REQUEST,
});
export const sendCsmInviteSuccess = () => ({
  type: SEND_CSM_INVITE_TO_FIRM_MEMBER_SUCCESS,
});
export const sendCsmInviteError = e => ({
  type: SEND_CSM_INVITE_TO_FIRM_MEMBER_ERROR,
  error: true,
  payload: e,
});

export const notificationRequest = () => ({
  type: NOTIFICATION_REQUEST,
});
export const notificationSuccess = data => ({
  type: NOTIFICATION_SUCCESS,
  payload: data,
});
export const notificationError = e => ({
  type: NOTIFICATION_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //

const fetchChainedMessages = txId => async (dispatch, getState, sdk) => {
  dispatch(fetchChainedMessagesRequest());

  async function request(page = 1, messages = []) {
    try {
      const response = await sdk.messages.query({
        transaction_id: txId,
        include: ['sender', 'sender.profileImage'],
        page,
        per_page: MESSAGES_PAGE_SIZE,
        ...IMAGE_VARIANTS,
      });

      const { totalPages } = response.data.meta;
      messages = [...messages, ...denormalisedResponseEntities(response)];

      if (totalPages > page) {
        return await request(page + 1, messages);
      }

      return messages;
    } catch (e) {
      throw e;
    }
  }

  try {
    const messages = await request();
    dispatch(fetchChainedMessagesSuccess(messages));
    return messages;
  } catch (e) {
    dispatch(fetchChainedMessagesError(storableError(e)));
    throw e;
  }
};

export const fetchChainedTransaction = id => (dispatch, getState, sdk) => {
  dispatch(fetchChainedTransactionRequest());

  return sdk.transactions
    .show(
      {
        id,
        include: ['customer', 'provider', 'listing'],
      },
      { expand: true }
    )
    .then(async response => {
      const transaction = denormalisedResponseEntities(response);
      try {
        await dispatch(fetchChainedMessages(id));
      } catch (e) {
        //do nothing
      }
      dispatch(fetchChainedTransactionSuccess(transaction));
      return transaction;
    })
    .catch(e => {
      dispatch(fetchChainedTransactionError(storableError(e)));
      throw e;
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

// Helper function for fetchTransaction call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = id => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  try {
    const promise = [];
    const response = await sdk.transactions.show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    );

    const transaction = response?.data?.data;
    const chainedTransactionId = transaction?.attributes?.protectedData?.chainedTransactionId;
    const finalPaymentTxId = transaction?.attributes?.metadata?.finalPaymentTxId;
    const jobTransactionId = transaction?.attributes?.metadata?.jobTransactionId;

    if (finalPaymentTxId ?? chainedTransactionId) {
      const ingoredTx = [
        TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
        TRANSITION_FINAL_PAYMENT_COMPLETED,
        TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
      ];
      const isIgnoredTxPresent = transaction?.attributes?.transitions?.findIndex(e =>
        ingoredTx.includes(e.transition)
      );

      promise.push(
        sdk.transactions
          .show({ id: new UUID(finalPaymentTxId ?? chainedTransactionId) })
          .then(finalPaymentTransaction => {
            const lastTransition = finalPaymentTransaction?.data?.data?.attributes?.lastTransition;
            Object.assign(response?.data?.data?.attributes, {
              lastTransition:
                isIgnoredTxPresent !== -1
                  ? transaction?.attributes?.lastTransition
                  : lastTransition,
              transitions: [
                ...transaction?.attributes?.transitions,
                ...finalPaymentTransaction?.data?.data?.attributes?.transitions,
              ].sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()),
            });
          })
      );
    }
    if (jobTransactionId) {
      promise.push(
        sdk.transactions.show({ id: new UUID(jobTransactionId) }).then(oldTransaction => {
          const transitions = [
            ...transaction?.attributes?.transitions,
            ...oldTransaction?.data?.data?.attributes?.transitions,
          ].sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix());

          Object.assign(response?.data?.data?.attributes, {
            lastTransition: transitions?.at(-1)?.transition,
            transitions,
          });
        })
      );
    }

    Promise.all(promise).then(() => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(response));
    });
  } catch (e) {
    dispatch(fetchTransactionError(storableError(e)));
    throw e;
  }
};

export const storeNotificationInDb = async params => {
  try {
    await storeNotification(params);
  } catch (error) {
  }
};
export const declineBrief = (transaction, transition) => async (dispatch, getState, sdk) => {
  try {
    if (getState().TransactionPage.actionInProgress) {
      return Promise.reject(new Error('Action in progress'));
    }
    const transactionId = transaction?.id?.uuid;
    const { customer = {}, provider = {} } = transaction || {};
    const providerName = provider?.id && getUserDetails(provider)?.fullName;
    const params = {
      id: transactionId,
      metadata: { isTransactionRead: false },
    };

    const response = await sdk.transactions.transition(
      { id: transactionId, transition, params: {} },
      { expand: true }
    );
    const notificationObject = {
      userId: customer.id.uuid,
      notificationType: TRANSACTION_NOTIFICATION,
      content: transition === TRANSITION_DECLINE_JOB_DESCRIPTION ? `${providerName} declined your job offer.` : `${providerName} declined your project brief.`,
      transactionId: transactionId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId}/details`,
    };
    storeNotificationInDb(notificationObject);
    updateMetadataThroughIsdk(params);
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(fetchLastTransitionSuccess());
    return response;
  } catch (error) {
    dispatch(actionError(storableError(e)));
    log.error(e, 'decline-brief-failed', {
      txId: transaction.id.uuid,
      transition,
    });
  }
  // throw e;
};

export const sendProposal = (transaction, params, userEmail, transition) => async (
  dispatch,
  getState,
  sdk
) => {
  const transactionId = transaction?.id?.uuid;
  try {
    if (getState().TransactionPage.actionInProgress) {
      return Promise.reject(new Error('Action in progress'));
    }
    dispatch(actionInProgress());

    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };
    const { customer = {}, provider = {} } = transaction || {};
    const providerName = provider?.id && getUserDetails(provider)?.fullName;

    const response = await transitionPrivileged({
      isTransition: true,
      bodyParams: { id: transactionId, transition, params },
      queryParams,
    });
    const notificationObject = {
      userId: customer.id.uuid,
      notificationType: TRANSACTION_NOTIFICATION,
      content: `${providerName} sent you a project proposal.`,
      transactionId: transactionId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId}/details`,
    };
    storeNotificationInDb(notificationObject);
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(actionSuccess());
    dispatch(fetchLastTransitionSuccess());
    userEmail && updateFirmUserDetails({ userEmail, action: SEND_PRIVATE_PROPOSAL });
    return Promise.resolve(response);
  } catch (error) {
    dispatch(actionError(storableError(error)));
    log.error(error, 'send-proposal-failed', {
      txId: transactionId,
      transition,
      // throw e;
    });
  }
};

export const updateProposal = (bodyParams, transaction) => async (dispatch, getState, sdk) => {
  const transactionId = transaction?.id?.uuid;
  try {
    const { customer = {}, provider = {} } = transaction || {};
    const processName = transaction?.attributes?.processName;
    const providerName =
      processName === TRANSACTION_BRIEF_PROCESS
        ? getUserDetails(customer)?.fullName
        : getUserDetails(provider)?.fullName;
    const authorId =
      processName === TRANSACTION_BRIEF_PROCESS ? provider.id.uuid : customer.id.uuid;
    if (getState().TransactionPage.actionInProgress) {
      return Promise.reject(new Error('Action in progress'));
    }
    dispatch(actionInProgress());

    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };
    const isProposalUpdated = await sdk.transactions.transition(
      { id: bodyParams.id, transition: TRANSITION_BRIEF_UPDATE_PROPOSAL, params: {} },
      { expand: true }
    );
    const notificationObject = {
      userId: authorId,
      notificationType: TRANSACTION_NOTIFICATION,
      content: `${providerName} edited the project proposal.`,
      transactionId: transactionId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId}/details`,
    };
    if (isProposalUpdated) {
      const response = await updateMetadataRequest({
        bodyParams,
        queryParams,
      });
      storeNotificationInDb(notificationObject);
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
      dispatch(actionSuccess());
      dispatch(fetchLastTransitionSuccess());
      return Promise.resolve(response);
    }
  } catch (e) {
    dispatch(actionError(storableError(e)));
    log.error(e, 'update-proposal-failed', {
      txId: bodyParams.id,
    });
  }
};

export const declineProposal = (transaction, transition, reason) => async (
  dispatch,
  getState,
  sdk
) => {
  const transactionId = transaction?.id && transaction.id.uuid;
  try {
    const { customer = {}, provider = {} } = transaction || {};
    const processName = transaction?.attributes?.processName;
    const isCustomerProcess = [TRANSACTION_BRIEF_PROCESS, TRANSACTION_JOB_DESCRIPTION_PROCESS, TRANSACTION_APPLY_JOB_PROCESS].includes(processName);
    const authorId = isCustomerProcess ? customer?.id?.uuid : provider.id.uuid;

    const userDetails = isCustomerProcess ? getUserDetails(provider) : getUserDetails(customer);
    const userName = userDetails?.fullName;

    const notificationObject = {
      userId: authorId,
      notificationType: TRANSACTION_NOTIFICATION,
      content: reason ? `${userName} rejected your application.` : `${userName} has declined your project proposal.`,
      transactionId: transactionId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId}/details`,
    };

    if (getState().TransactionPage.actionInProgress) {
      return Promise.reject(new Error('Action in progress'));
    }
    dispatch(actionInProgress());
    const bodyParams = {
      id: transactionId,
      params: {
        metadata: {
          isTransactionRead: false,
          reason
        }
      },
      transition
    };
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };
    //  response = await sdk.transactions.transition(
    //   { id: transactionId, transition, params: {metadata: reason} },
    //   { expand: true }
    // );
    const response = await transitionPrivileged({
      isTransition: true,
      bodyParams: bodyParams,
      queryParams,
    });
    if (response) {
      storeNotification(notificationObject);
    }
    // updateMetadataThroughIsdk(params);
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(actionSuccess());
    dispatch(fetchLastTransitionSuccess());
    return response;
  } catch (e) {
    dispatch(actionError(storableError(e)));
    log.error(e, 'decline-proposal-failed', {
      txId: transactionId,
      transition,
    });
  }
};

export const acceptBriefProposal = (
  orderParams,
  briefTransaction,
  emailParams,
  lastTransition,
  processName,
  attachedDocuments
) => async (dispatch, getState, sdk) => {
  dispatch(actionInProgress());
  const transition = TRANSITION_BRIEF_ACCEPT_PROPOSAL;

  const id = briefTransaction.id.uuid;
  const partnerId =
    processName === TRANSACTION_BRIEF_PROCESS
      ? briefTransaction.customer.id.uuid
      : briefTransaction.provider.id.uuid;
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  Object.assign(orderParams?.metadata, { isTransactionRead: false });

  try {
    const partnerProfile = await dispatch(fetchPartnerProfile(partnerId));
    const proposalOrder = await dispatch(
      acceptProposal(
        {
          listingId: partnerProfile.id.uuid,
          ...orderParams,
        },
        lastTransition === TRANSITION_BRIEF_UPDATE_PROPOSAL &&
          processName !== TRANSACTION_BRIEF_PROCESS
          ? id
          : null,
        emailParams,
        attachedDocuments
      )
    );
    const chainedTransactionId = proposalOrder.id.uuid;
    const response = await sdk.transactions.transition(
      {
        id,
        transition,
        params: {
          protectedData: {
            chainedTransactionId,
            chainedTransaction: chainedTransactionId && JSON.stringify(proposalOrder),
          },
        },
      },
      queryParams
    );

    dispatch(addMarketplaceEntities(response));
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(actionSuccess());
    dispatch(fetchLastTransitionSuccess());
    return chainedTransactionId;
  } catch (e) {
    dispatch(actionError(storableError(e)));
    log.error(e, 'accept-brief-proposal-failed');
  }
};

const handleCreateFolder = async params => {
  const response = await createFolder(params);
  if (response?.status === 'success') return response;
};

export const acceptProposal = (
  orderParams,
  transactionId,
  emailParams,
  attachedDocuments
) => async (dispatch, getState, sdk) => {
  const { isProviderIndianOrigin, firmEmails } = emailParams;

  // makeRequestToCustomEndpoint('POST', '/api/emails/send-email-using-zepto', emailParams)
  //   .then(response => {
  //     return response;
  //   })
  //   .catch(e => {
  //     console.error(e);
  //   });

  const isTransition = !!transactionId;
  if (isTransition) {
    dispatch(actionInProgress());
  }
  const transition = isTransition
    ? TRANSITION_ACCEPT_PROPOSAL
    : TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF;
  const { paymentFee, customerId, paymentTerms } = orderParams.metadata.proposal;
  const bookingData = {
    paymentFee,
    customerId,
    paymentTerms,
    shouldUsePaymentTerms: true,
  };
  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: {},
    }
    : {
      // processAlias: config.proposalProcessAlias,
      processAlias: isProviderIndianOrigin
        ? config.prepaymentProcessIndia
        : config.proposalProcessAlias,
      transition,
      params: {
        ...orderParams,
      },
    };
  const queryParams = {
    include: ['booking', 'provider', 'customer', 'listing'],
    expand: true,
  };

  const handleSuccess = async response => {
    try {
      const entities = denormalisedResponseEntities(response);
      const transaction = entities[0];
      const txId = transaction.id.uuid;
      const { customer = {}, provider = {} } = transaction?.id && transaction;
      const authorId = provider?.id && getUserDetails(provider).id;
      const customerName = customer?.id && getUserDetails(customer).fullName;
      const notificationObject = {
        userId: authorId,
        notificationType: TRANSACTION_NOTIFICATION,
        content: `${customerName} has accepted your project proposal.`,
        transactionId: txId,
        notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
      };

      dispatch(addMarketplaceEntities(response));
      if (!isTransition) {
        dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
        dispatch(actionSuccess());
        dispatch(fetchLastTransitionSuccess());
      }
      if (firmEmails?.length) {
        for (let email of firmEmails) {
          updateFirmUserDetails({ userEmail: email, action: PROJECT_UNDERWAY, projectId: txId });
        }
      }
      const { folderId, link } = await handleCreateFolder({ txId, attachedDocuments });
      const params = {
        txId: new UUID(txId),
        bodyParam: {
          isTransactionRead: false,
          boxFolder: { folderId, boxSharedLink: link },
        },
      };
      dispatch(updateTransactionMetaData(params));
      if (txId) {
        await storeNotification(notificationObject);
      }
      return transaction;
    } catch (error) {
      return;
    }
  };

  const handleError = e => {
    if (!isTransition) {
      throw e;
    }

    dispatch(actionError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId } : {};
    log.error(e, 'accept-proposal-failed', {
      ...transactionIdMaybe,
    });
  };

  if (isTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const payProposal = id => (dispatch, getState, sdk) => {
  if (getState().TransactionPage.actionInProgress) {
    return Promise.reject(new Error('Action in progress'));
  }
  dispatch(actionInProgress());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_REQUEST_PAYMENT, params: {} })
    .then(() =>
      sdk.transactions.transition(
        { id, transition: TRANSITION_CONFIRM_PAYMENT, params: {} },
        { expand: true }
      )
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(actionSuccess());
      return response;
    })
    .catch(e => {
      dispatch(actionError(storableError(e)));
      log.error(e, 'pay-failed', {
        txId: id,
      });
    });
};

export const changeMilestones = bodyParams => async (dispatch, getState, sdk) => {
  const { currentTransaction, emailParams, currentUser, ...filteredParams } = bodyParams;

  const txId = currentTransaction.id.uuid;

  const lastTransition = emailParams?.lastTransition;
  const { type = '' } = filteredParams && filteredParams.notification; //"addMilestone"

  try {
    const { customer = {}, provider = {} } = currentTransaction?.id && currentTransaction;
    const customerId = customer?.id && getUserDetails(customer).id;
    const providerName = provider?.id && getUserDetails(provider).fullName;
    const userEmail = currentUser?.id && getUserDetails(currentUser).email;
    const { collaborationMetaData = [] } =
      (currentTransaction.id && currentTransaction.attributes.metadata) || {};
    const activeCollaborators = collaborationMetaData.filter(
      ({ email, status }) => email !== userEmail && status === INVITE_STATUS_ACTIVE
    );
    const notificationObject = {
      userId: customerId,
      notificationType: MILESTONE_NOTIFICATION,
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/milestone`,
    };
    notificationObject.content = generateNotificationContent(type, providerName);

    const recipientIds = [
      customerId,
      ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
    ].filter(Boolean);

    //   // if (getState().TransactionPage.actionInProgress) {
    //   //   return Promise.reject(new Error('Action in progress'));
    //   // }
    dispatch(actionInProgress());
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };
    const isCollaborator = emailParams?.isCollaborator;
    const transition = getTransition(type, lastTransition, isCollaborator);
    const isMilestoneCreated = await sdk.transactions.transition(
      { id: filteredParams.id, transition, params: {} },
      { expand: true }
    );

    if (isMilestoneCreated) {
      const response = await updateMetadataRequest({
        bodyParams: filteredParams,
        queryParams,
      });
      // Combine and filter customer and provider IDs
      // Loop through recipient IDs
      for (const userId of recipientIds) {
        // Send notification with user-specific content
        await storeNotificationInDb({
          ...notificationObject,
          userId: userId,
        });
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(
        fetchLastTransitionRequest(isMilestoneCreated?.data?.data?.attributes?.lastTransition)
      );
      dispatch(actionSuccess());
      dispatch(fetchLastTransitionSuccess());
      sendEmailUsingZepto({ ...emailParams, step: transition })
        .then(response => response)
        .catch(e => {
          // console.log(e);
        });
      return Promise.resolve(response);
    }
  } catch (e) {
    dispatch(actionError(storableError(e)));
    log.error(e, 'update-milestones-failed', {
      txId: filteredParams.id,
    });
  }
};

export const completeProposal = (params) => async (dispatch, getState, sdk) => {
  if (getState().TransactionPage.actionInProgress) {
    return Promise.reject(new Error('Action in progress'));
  }
  dispatch(actionInProgress());
  const { currentTransaction, emailParams, currentUser } = params;
  const transactionId = currentTransaction.id.uuid;

  try {

    const params = {
      id: transactionId,
      metadata: { isTransactionRead: false },
    };
    const response = await sdk.transactions.transition(
      { id: transactionId, transition: TRANSITION_REQUEST_MARK_COMPLETE, params: {} },
      { expand: true }
    );
    const { customer = {}, provider = {} } = currentTransaction || {};
    const customerName = customer.id && getUserDetails(customer).fullName;
    const { collaborationMetaData = [] } =
      (currentTransaction.id && currentTransaction.attributes.metadata) || {};
    const userEmail = currentUser?.id && getUserDetails(currentUser).email;
    const activeCollaborators = collaborationMetaData.filter(
      ({ email, status }) => email !== userEmail && status === INVITE_STATUS_ACTIVE
    );

    const recipientIds = [
      provider.id.uuid,
      ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
    ].filter(Boolean);

    const notificationObject = {
      userId: provider.id.uuid,
      notificationType: TRANSACTION_NOTIFICATION,
      content: `${customerName} marked project as completed`,
      transactionId: transactionId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId}/details`,
    };
    updateMetadataThroughIsdk(params);
    sendEmailUsingZepto(emailParams)
      .then(response => response)
      .catch(e => console.error(e));
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(actionSuccess());
    dispatch(fetchLastTransitionSuccess());
    // Combine and filter customer and provider IDs
    // Loop through recipient IDs
    for (const userId of recipientIds) {
      // Send notification with user-specific content
      await storeNotificationInDb({
        ...notificationObject,
        userId: userId,
      });
    }
    return response;
  } catch (e) {
    dispatch(actionError(storableError(e)));
    log.error(e, 'complete-failed', {
      txId: transactionId,
      transition: TRANSITION_REQUEST_MARK_COMPLETE,
    });
  }
};

export const completeProposalThroughIntegration = (transaction, emailParams) => async (
  dispatch,
  getState,
  sdk
) => {
  const { currentUser } = emailParams;

  const txId = transaction?.id?.uuid;
  try {
    const { customer = {}, provider = {} } = transaction || {};
    const customerId = customer?.id && getUserDetails(customer).id;
    const providerId = provider?.id && getUserDetails(provider).id;
    const userName = currentUser.id && getUserDetails(currentUser).firstName;
    const currentUserId = currentUser.id && getUserDetails(currentUser).id;
    const currentUserEmail = currentUser.id && getUserDetails(currentUser).email;
    const { collaborationMetaData = [] } = transaction?.attributes?.metadata || {};
    const activeCollaborators = collaborationMetaData.filter(
      ({ email, status }) => email !== currentUserEmail && status === INVITE_STATUS_ACTIVE
    );

    // if (getState().TransactionPage.actionInProgress) {
    //   return Promise.reject(new Error('Action in progress'));
    // }
    dispatch(actionInProgress());


    // Combine and filter author and collaborator IDs
    const recipientIds = [
      customerId,
      providerId,
      ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
    ].filter(Boolean);
    const notificationObject = {
      notificationType: TRANSACTION_NOTIFICATION,
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
      content: `${userName} marked project as completed`,

    };
    const transitionData = {
      id: new UUID(txId),
      transition: TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR,
      params: {
        metadata: { isTransactionRead: false },
        protectedData: {
          proposalCompletedBy: currentUserId,
        },
      },
    };

    const response = await completeProposalThroughIsdk({ transitionData })
    if (response.status === 200) {
      sendEmailUsingZepto(emailParams)
        .then(response => response)
        .catch(e => console.error(e));
      for (const userId of recipientIds) {
        // Send notification with user-specific content
        await storeNotificationInDb({
          ...notificationObject,
          userId: userId,
        });
      }
    }
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(actionSuccess());
    dispatch(fetchLastTransitionSuccess());
    return response;

  } catch (e) {
    dispatch(actionError(storableError(e)));
    log.error(e, 'complete-failed', {
      txId,
      transition: TRANSITION_REQUEST_MARK_COMPLETE_OPERATOR,
    });
  }
};

export const acceptSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  // return sdk.transactions
  //   .transition({ id, transition: TRANSITION_ACCEPT, params: {} }, { expand: true })
  //   .then(response => {
  //     dispatch(addMarketplaceEntities(response));
  //     dispatch(acceptSaleSuccess());
  //     dispatch(fetchCurrentUserNotifications());
  //     return response;
  //   })
  //   .catch(e => {
  //     dispatch(acceptSaleError(storableError(e)));
  //     log.error(e, 'accept-sale-failed', {
  //       txId: id,
  //       transition: TRANSITION_ACCEPT,
  //     });
  //     throw e;
  //   });
};

export const declineSale = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  // return sdk.transactions
  //   .transition({ id, transition: TRANSITION_DECLINE, params: {} }, { expand: true })
  //   .then(response => {
  //     dispatch(addMarketplaceEntities(response));
  //     dispatch(declineSaleSuccess());
  //     dispatch(fetchCurrentUserNotifications());
  //     return response;
  //   })
  //   .catch(e => {
  //     dispatch(declineSaleError(storableError(e)));
  //     log.error(e, 'reject-sale-failed', {
  //       txId: id,
  //       transition: TRANSITION_DECLINE,
  //     });
  //     throw e;
  //   });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;
  const param = {
    id,
    metadata: { isTransactionRead: false },
  };
  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      updateMetadataThroughIsdk(param);
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
      dispatch(actionSuccess());
      dispatch(sendReviewSuccess());
      dispatch(fetchLastTransitionSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
  const transition = getReview1Transition(role === CUSTOMER);
  const include = REVIEW_TX_INCLUDES;

  const param = {
    id,
    metadata: { isTransactionRead: false },
  };
  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      updateMetadataThroughIsdk(param);
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
      dispatch(actionSuccess());
      dispatch(sendReviewSuccess());
      dispatch(fetchLastTransitionSuccess());
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const getCustomerData = email => async (dispatch, getState, sdk) => {
  try {
    const {
      data: { data: user = {} },
    } = (await getUserData({ email })) || {};
    return user;
  } catch (error) {
    // console.log(error);
  }
};

export const sendReview = (role, tx, reviewRating, reviewContent) => async (
  dispatch,
  getState,
  sdk
) => {
  const params = { reviewRating, reviewContent };
  const isCustomer = role === CUSTOMER;
  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);
  const briefId = tx?.attributes?.protectedData?.brief?.id;
  const expertListingId = tx?.listing?.id?.uuid;
  const listingId = isCustomer ? expertListingId : briefId;
  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
};

export const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

export const setUpdateMetadata = (attachments, txId, collaborationMetaData) => async (
  dispatch,
  getState,
  sdk
) => {
  return updateMetadata({ attachments, txId, collaborationMetaData })
    .then(res => {
      dispatch(fetchTransaction(txId));
    })
    .catch(e => {
      // console.log('error updating metadata');
    });
};

// Show Firm Listing

export const showFirmListing = () => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser());
  const currentUser = await getState().user?.currentUser;
  const listingId =
    !!currentUser?.id && currentUser?.attributes?.profile?.publicData?.firmId
      ? currentUser?.attributes?.profile?.publicData?.firmId
      : currentUser?.attributes?.profile?.publicData?.linkedToFirms?.at(0)?.firmId;
  dispatch(showFirmListingRequest());
  try {
    if (listingId) {
      const params = {
        id: listingId,
        include: ['author', 'author.profileImage', 'author.protectedData', 'images'],
      };
      const response = await sdk.listings.show(params);
      if (response.data) {
        dispatch(showFirmListingSuccess(response?.data?.data?.id));
        dispatch(addMarketplaceEntities(response));
      }
    }
  } catch (error) {
    dispatch(showFirmListingError(storableError(error)));
  }
};
// Collaboration Invite
export const collaborationInvite = (
  params,
  collaborationTransactionData,
) => async (dispatch, getState, sdk) => {
  dispatch(collaborationInviteRequest());
  const {
    currentTransaction,
    invitedByUserRole,
    inviteeRole,
    ...filteredCollaborationData
  } = collaborationTransactionData;
  const { collaborationMetaData, ...filteredParams } = params;
  const transactionId = currentTransaction?.id?.uuid;


  try {
    const { customer = {}, provider = {} } = currentTransaction || {};
    const authorId =
      invitedByUserRole === USER_ROLE_CLIENT
        ? getUserDetails(provider).id
        : getUserDetails(customer).id;

    const activeCollaborators =
      collaborationMetaData?.length > 0 &&
      collaborationMetaData.filter(collaborator => collaborator.status === INVITE_STATUS_ACTIVE);

    const sentCollaborationInvite = await sendEmailUsingZepto(filteredParams);

    const showUserDetails = await fetchFirmMember({ userEmail: filteredParams?.userEmail });
    const { id = null, collaborationTransactionDetails, firmName } = showUserDetails;
    const notificationObject = {
      notificationType: TRANSACTION_NOTIFICATION,
      transactionId: transactionId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId}/details`,
    };
    const firmDetails = firmName ? ` from ${firmName}` : '';
    notificationObject.content = `${showUserDetails?.fullName}${firmDetails} has been invited to the project as a ${inviteeRole}`;

    // Combine and filter author and collaborator IDs
    const recipientIds = [
      authorId,
      ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
    ].filter(Boolean);

    // Send notifications to the author and collaborators
    for (const userId of recipientIds) {
      const userNotification = {
        ...notificationObject,
        userId: userId,
      };
      await storeNotificationInDb(userNotification);
    }

    const isTransactionExits = collaborationTransactionDetails.some(
      tnx => tnx?.id === filteredCollaborationData?.id
    );
    if (!isTransactionExits) {
      collaborationTransactionDetails.push(filteredCollaborationData);
      const data = {
        id: new UUID(id),
        metadata: {
          collaborationTransactionDetails,
        },
      };
      const updatedProfile = await updateUserProfile({
        data,
      });
    }
    const filteredArray = collaborationMetaData.map(u =>
      u.email === showUserDetails.email
        ? { profileImage: showUserDetails.profileImage, fullName: showUserDetails.fullName, ...u }
        : { ...u }
    );
    const response = await updateMetadata({
      collaborationMetaData: filteredArray,
      txId: transactionId,
    });
    dispatch(collaborationInviteSuccess());
    const txId = filteredCollaborationData?.id;
    dispatch(getIntegrationTxnData(txId));
    return data;
  } catch (error) {
    dispatch(collaborationInviteError(storableError(error)));
  }
};

export const getIntegrationTxnData = txId => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  try {
    const promise = [];
    const response = txId && (await getTransaction({ txId }));
    const listingId = listingRelationship(response).id;
    const entities = updatedEntities({}, response.data);
    const listingRef = { id: listingId, type: 'listing' };
    const transactionRef = { id: new UUID(txId), type: 'transaction' };
    const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
    const listing = denormalised[0];
    const transaction = denormalised[1];

    const { lastTransition, protectedData = {} } = transaction.attributes;
    const isProposalTransaction = TRANSITIONS.includes(lastTransition);
    const { chainedTransactionId, brief } = protectedData;

    const idTransaction = chainedTransactionId || txId;

    if (!!isProposalTransaction && !!chainedTransactionId) {
      dispatch(fetchChainedTransaction(chainedTransactionId));
    }
    if (chainedTransactionId) {
      const ingoredTx = [
        TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
        TRANSITION_FINAL_PAYMENT_COMPLETED,
        TRANSITION_ACCEPT_PROPOSAL_AFTER_BRIEF,
      ];
      const isIgnoredTxPresent = transaction?.attributes?.transitions?.findIndex(e =>
        ingoredTx.includes(e.transition)
      );

      promise.push(
        getTransaction({ txId: chainedTransactionId }).then(chainedTransaction => {
          const lastTransition = chainedTransaction?.data?.data?.attributes?.lastTransition;
          Object.assign(response?.data?.data?.attributes, {
            lastTransition:
              isIgnoredTxPresent !== -1 ? transaction?.attributes?.lastTransition : lastTransition,
            transitions: [
              ...transaction?.attributes?.transitions,
              ...chainedTransaction?.data?.data?.attributes?.transitions,
            ].sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()),
          });
        })
      );
    }

    Promise.all(promise).then(() => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(response));
      return response;
    });
    return response;
  } catch (error) {
    dispatch(fetchTransactionError(error));
  }
};

export const changeMilestoneThroughIntegration = (bodyParams) => async (
  dispatch,
  getState,
  sdk
) => {
  const { currentTransaction, emailParams, currentUser, ...filteredParams } = bodyParams;
  const { type = '' } = filteredParams && filteredParams.notification; //"addMilestone"



  const lastTransition = emailParams?.lastTransition;
  const txId = currentTransaction.id.uuid;
  try {
    // if (getState().TransactionPage.actionInProgress) {
    //   return Promise.reject(new Error('Action in progress'));
    // }
    dispatch(actionInProgress());
    const { customer = {}, provider = {} } = (currentTransaction?.id && currentTransaction) || {};
    const customerId = customer?.id && getUserDetails(customer).id;
    const providerId = provider?.id && getUserDetails(provider).id;
    const userName = currentUser.id && getUserDetails(currentUser).firstName;
    const currentUserEmail = currentUser.id && getUserDetails(currentUser).email;
    const { collaborationMetaData = [] } = currentTransaction?.attributes?.metadata || {};
    const activeCollaborators = collaborationMetaData.filter(
      ({ email, status }) => email !== currentUserEmail && status === INVITE_STATUS_ACTIVE
    );

    // Combine and filter author and collaborator IDs
    const recipientIds = [
      customerId,
      providerId,
      ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
    ].filter(Boolean);
    const notificationObject = {
      notificationType: MILESTONE_NOTIFICATION,
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/milestone`,

    };
    notificationObject.content = generateNotificationContent(type, userName);
    // Send notifications to the author and collaborators
    for (const userId of recipientIds) {
      const userNotification = {
        ...notificationObject,
        userId: userId,
      };
      await storeNotificationInDb(userNotification);
    }

    const isCollaborator = emailParams?.isCollaborator;
    const transition = getTransition(type, lastTransition, isCollaborator);
    const metadata = bodyParams.metadata;
    const transitionData = {
      id: txId,
      transition,
      params: {
        metadata: {
          ...metadata,
        },
      },
    };

    const isMilestoneCreated = await updateMilestone({
      transitionData,
    });

    if (isMilestoneCreated.status) {
      sendEmailUsingZepto({ ...emailParams, step: transition })
        .then(response => response)
        .catch(e => { });
      dispatch(
        fetchLastTransitionRequest(isMilestoneCreated?.data?.data?.attributes?.lastTransition)
      );
      dispatch(actionSuccess());
      dispatch(fetchLastTransitionSuccess());
    }
    const response = await dispatch(getIntegrationTxnData(txId));
    return response
  } catch (error) {
    dispatch(actionError(storableError(error)));
    log.error(error, 'update-milestones-failed', {
      txId,
    });
  }
};

//Remove user
export const removeMemberFromTransactionPage = params => async (dispatch, getState, sdk) => {
  try {
    const {
      collaboratorEmail,
      currentTransaction,
      authorName,
      authorEmail,
      firmName,
      listingTitle,
      currentUser,
    } = params;
    const { customer = {}, provider = {} } = (currentTransaction?.id && currentTransaction) || {};
    const txID = currentTransaction?.id?.uuid;
    const customerId = customer?.id && getUserDetails(customer).id;
    const providerId = provider?.id && getUserDetails(provider).id;
    const providerEmail = provider?.id && getUserDetails(provider)?.email;
    const userName = currentUser.id && getUserDetails(currentUser).firstName;
    const { collaborationMetaData = [] } = currentTransaction?.attributes?.metadata || {};
    const transactionRole = collaborationMetaData.find(user => user.email === collaboratorEmail)
      ?.invitedByUserRole;
    const activeCollaborators = collaborationMetaData.filter(
      ({ email, status }) => email !== collaboratorEmail && status === INVITE_STATUS_ACTIVE
    );
    const authorId = authorEmail === providerEmail ? customerId : providerId;
    const firmDetails = firmName ? ` from ${firmName}` : '';

    // Combine and filter author and collaborator IDs
    const recipientIds = [
      authorId,
      ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
    ].filter(Boolean);
    const notificationObject = {
      notificationType: TRANSACTION_NOTIFICATION,
      transactionId: txID,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txID}/details`,
      content: `${userName}${firmDetails} has been removed
      from the project as a  ${transactionRole}`,
    };
    // Send notifications to the author and collaborators
    for (const userId of recipientIds) {
      const userNotification = {
        ...notificationObject,
        userId: userId,
      };
      await storeNotificationInDb(userNotification);
    }

    const emailObject = {
      authorName,
      authorEmail,
      txID,
      firmName,
      listingTitle,
      step: REMOVE_FIRM_MEMBER_FROM_TRANSACTION,
      userEmail: collaboratorEmail,
    };
    await sendEmailUsingZepto(emailObject);
    await showAndUpdateCollaboratorProfile({ collaboratorEmail, emailObject });

    const filterCollaborationMetaData =
      !!collaborationMetaData?.length &&
      collaborationMetaData.filter(cmd => cmd?.email !== collaboratorEmail);
    await updateMetadata({
      collaborationMetaData: filterCollaborationMetaData,
      txId: txID,
    });
    dispatch(fetchTransaction(new UUID(txID)));
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};

export const sendChatNotificationEmail = params => async (dispatch, sdk, getState) => {
  try {
    await sendEmailUsingZepto(params);
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};

export const updateFinalPaymentDetails = params => async (dispatch, getState, sdk) => {
  const { currentTransaction, metadata, type, emailParams } = params;
  dispatch(actionInProgress());
  const transactionId = currentTransaction?.id?.uuid;
  if (type === OFFLINE) {
    sdk.transactions
      .transition(
        {
          id: transactionId,
          transition: TRANSITION_OFFLINE_PAYMENT_REQUESTED,
          params: {},
        },
        { expand: true }
      )
      .then(response => {
        const { customer = {}, provider = {} } = currentTransaction || {};
        const authorId = provider.id && getUserDetails(provider).id;
        const customerName = customer.id && getUserDetails(customer).fullName;
        const notificationObject = {
          userId: authorId,
          notificationType: TRANSACTION_NOTIFICATION,
          content: `${customerName} accepted your project proposal. Please contact InsightGig to proceed further.`,
          transactionId: transactionId,
          notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${transactionId}/details`,
        };
        updateMetadataThroughIsdk({ id: transactionId, metadata });
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
        dispatch(actionSuccess());
        dispatch(fetchLastTransitionSuccess());
        sendEmailUsingZepto(emailParams);
        storeNotificationInDb(notificationObject);
      })
      .catch(e => {
        dispatch(actionError(storableError(e)));
      });
  } else if (type === ONLINE) {
    dispatch(updateFinalPaymentDetailsRequest());
    try {
      const response = await updateMetadataThroughIsdk({ id: transactionId, metadata });
      if (response) {
        dispatch(fetchTransaction(transactionId));
        dispatch(updateFinalPaymentDetailsSuccess());
      }
      return response;
    } catch (error) {
      dispatch(actionError(storableError(error)));
    }
  }
};

export const createZoomAuth = params => async (dispatch, getState, sdk) => {
  const { code, txId } = params;
  if (code && txId) {
    const response = await createZoomToken({ code, txId });
    if (response.message === 'Success') dispatch(fetchCurrentUser());
  }
};

export const handleJobTransition = (transaction, transition) => async (dispatch, getState, sdk) => {
  dispatch(actionInProgress());
  try {
    const { currentUser = {} } = getState().user || {};
    const userRole = currentUser?.id && getUserRole(currentUser);
    const txId = transaction?.id?.uuid;
    const customer = transaction.id && transaction.customer;
    const provider = transaction.id && transaction.provider;
    const providerId = userRole === USER_ROLE_CLIENT ? getUserDetails(provider)?.id : getUserDetails(customer)?.id;
    const customerName = userRole === USER_ROLE_CLIENT ? getUserDetails(customer)?.fullName : getUserDetails(provider)?.fullName;

    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };

    const params = {
      id: txId,
      metadata: { isTransactionRead: false },
    };
    const interviewType = transition === TRANSITION_CONFIRM_FIRST_INTERVIEW ? 'first' : 'second';
    const notificationObject = {
      userId: providerId,
      notificationType: JOB_NOTIFICATION,
      content: (() => {
        switch (transition) {
          case TRANSITION_ACCEPT_JOB_OFFER:
            return `${customerName} accepted the job offer`;
          case TRANSITION_DECLINE_JOB_OFFER:
            return `${customerName} declined the job offer`;
          case TRANSITION_CONFIRM_FIRST_INTERVIEW:
          case TRANSITION_CONFIRM_SECOND_INTERVIEW:
            return `${customerName} marked the ${interviewType} interview complete`;
          case TRANSITION_COMFIRM_CONTRACT_SIGNATURE:
            return `The offline contract was signed.`;
          default:
            return `${customerName} marked the ${interviewType} interview complete`;
        }
      })(),
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
    };

    const response = await sdk.transactions.transition({ id: txId, transition, params: {} }, queryParams);
    if (response.data.data.id) {
      storeNotificationInDb(notificationObject);
    }
    dispatch(addMarketplaceEntities(response));
    updateMetadataThroughIsdk(params);
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(fetchLastTransitionSuccess());
    dispatch(actionSuccess());
    return response;
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};

export const acceptJobApplication = params => async (dispatch, getState, sdk) => {
  try {
    const { id, isChainedTransaction, brief, proposal, partnerId } = params;
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };

    if (isChainedTransaction) {
      const partnerProfile = await dispatch(fetchPartnerProfile(partnerId));
      const bodyParams = {
        processAlias: config.jobDescriptionProcess,
        transition: TRANSITION_ACCEPT_JOB_APPLICATION_AFTER_JOB,
        params: {
          listingId: partnerProfile.id.uuid,
          protectedData: { brief },
          metadata: { proposal, jobTransactionId: id, isTransactionRead: true },
        },
      };
      const response = await initiatePrivileged({ bodyParams, queryParams });
      return response.data.data;
    } else {
      const bodyParams = {
        id: id,
        transition: TRANSITION_ACCEPT_JOB_APPLICATION,
        params: {},
      };
      const response = await transitionPrivileged({ bodyParams, queryParams, isTransition: true });
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
      return response;
    }
  } catch (error) {
    // console.log(error)
    // dispatch(createMeetingError(storableError(error)));
  }
};

export const initChainedApplication = params => async (dispatch, getState, sdk) => {
  try {
    const { customerId, customerName, ...filteredParams } = params;
    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };
    const chainedTransaction = await dispatch(
      acceptJobApplication({ ...filteredParams, isChainedTransaction: true })
    );

    const chainedTransactionId = chainedTransaction.id.uuid;
    if (chainedTransactionId) {
      const notificationObject = {
        userId: customerId,
        notificationType: JOB_NOTIFICATION,
        content: `${customerName} submitted a job application.`,
        transactionId: chainedTransactionId,
        notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${chainedTransactionId}/details`,
      };
      await storeNotificationInDb(notificationObject);
    }

    if (chainedTransactionId) {
      const response = await sdk.transactions.transition(
        {
          id: params.id,
          transition: TRANSITION_ACCEPT_JOB_APPLICATION,
          params: {
            protectedData: {
              chainedTransaction: chainedTransactionId,
            },
          },
        },
        queryParams
      );

      return chainedTransactionId;
    }
  } catch (error) {
    // console.log(error)
    // dispatch(createMeetingError(storableError(error)));
  }
};

export const requestCaseStudy = params => async (dispatch, getState, sdk) => {
  dispatch(actionInProgress());
  try {
    const { transaction, param, transition, isTransition, bookingData } = params;
    const txId = transaction?.id?.uuid;
    const {customer, provider} = transaction.id && transaction;
    const providerId = provider.id && getUserDetails(provider)?.id;
    const customerName = customer.id && getUserDetails(customer)?.fullName;
    const providerName = provider.id && getUserDetails(provider)?.fullName;

    const notificationObject = {
      userId: providerId,
      notificationType: JOB_NOTIFICATION,
      content: (() => {
        switch (transition) {
          case TRANSITION_REQUEST_CONTRACT_SIGNATURE:
            return `${customerName} chose to sign the contract offline.`;
          case TRANSITION_REQUEST_OFFLINE_PAYMENT:
            return `${customerName} has done offline payment`;
          case TRANSITION_SUBMIT_CASE_STUDY:
            return `${customerName} has submitted a case study`;
          case TRANSITION_DECLINE_FIRST_INTERVIEW:
            return `${providerName} has declined first interview`;
          case TRANSITION_DECLINE_SECOND_INTERVIEW:
            return `${providerName} has declined second interview`;
          default:
            return `${customerName} offered you the job.`;
        }
      })(),
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
    };

    const queryParams = {
      include: ['booking', 'provider', 'customer', 'listing'],
      expand: true,
    };

    const bodyParams = {
      id: txId,
      transition,
      params: {
        metadata: { ...param, isTransactionRead: false },
      },
    };

    const response = await transitionPrivileged({
      isTransition,
      bodyParams,
      queryParams,
      bookingData,
    });
    if (response.data.data.id) {
      storeNotificationInDb(notificationObject);
    }
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
    dispatch(actionSuccess());
    dispatch(fetchLastTransitionSuccess());

    return response;
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};

export const requestScheduleInterview = params => async (dispatch, getState, sdk) => {
  dispatch(createMeetingRequest());
  try {
    const { transition, currentTransaction, startDateTime, endDateTime } = params;
    const txId = currentTransaction?.id?.uuid;
    const customer = currentTransaction.id && currentTransaction.customer;
    const provider = currentTransaction.id && currentTransaction.provider;
    const providerId = provider.id && getUserDetails(provider)?.id;
    const customerName = customer.id && getUserDetails(customer)?.fullName;

    // Calculate duration in minutes
    const durationInMinutes = Math.floor((new Date(endDateTime) - new Date(startDateTime)) / 1000 / 60);

    // Format date and time strings
    const dateString = new Date(startDateTime).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const timeString = new Date(startDateTime).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    const scheduleMeeting = await scheduleGoogleMeeting(params);

    const notificationObject = {
      userId: providerId,
      notificationType: JOB_NOTIFICATION,
      content: `${customerName} has scheduled a meeting on ${dateString} at ${timeString} for ${durationInMinutes} minutes via Google Meet. Please check your calendar.`,
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
    };

    if (scheduleMeeting.event) {
      const firstInterviewData = { date: scheduleMeeting.event.data.start.dateTime };
      const queryParams = {
        include: ['booking', 'provider'],
        expand: true,
      };
      const bodyParams = {
        id: txId,
        transition,
        params: {
          metadata: { isTransactionRead: false, firstInterviewData },
        },
      };

      const response = await transitionPrivileged({ isTransition: true, bodyParams, queryParams });
      if (response.data.data.id) {
        storeNotificationInDb(notificationObject);

      }
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLastTransitionRequest(response?.data?.data?.attributes?.lastTransition));
      dispatch(createMeetingSuccess());
      dispatch(fetchLastTransitionSuccess());
      return scheduleMeeting;
    }
  } catch (error) {
    dispatch(createMeetingError(storableError(error)));
  }
};

export const handleInitOnlineContract = params => async (dispatch, getState, sdk) => {
  dispatch(actionInProgress());
  try {
    const { formData, transaction, clientSign } = params;
    const txId = transaction?.id?.uuid;
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };

    const customer = transaction.id && transaction.customer;
    const provider = transaction.id && transaction.provider;
    const providerId = provider.id && getUserDetails(provider)?.id;
    const customerName = customer.id && getUserDetails(customer)?.fullName;

    const notificationObject = {
      userId: providerId,
      notificationType: JOB_NOTIFICATION,
      content: `${customerName} signed a contract`,
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
    };
    const response = await axios.post(`${apiBaseUrl()}/api/uploadContract`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 200 && response.data.status === 'success') {
      const contractDetails = {
        contractType: ONLINE,
        request_id: response?.data?.requests?.request_id,
        clientSign,
      };
      const bodyParams = {
        id: txId,
        transition: TRANSITION_REQUEST_CONTRACT_SIGNATURE,
        params: {
          metadata: { contractDetails, isTransactionRead: false },
        },
      };

      const transaction = await transitionPrivileged({
        isTransition: true,
        bodyParams,
        queryParams,
      });
      if (transaction.data.data.id) {
        storeNotificationInDb(notificationObject);
      }
      dispatch(addMarketplaceEntities(transaction));
      dispatch(fetchLastTransitionRequest(transaction?.data?.data?.attributes?.lastTransition));
      dispatch(actionSuccess());
      dispatch(fetchLastTransitionSuccess());
      return response?.data?.requests?.request_id;
    } else {
      dispatch(actionError(storableError(error)));
    }
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};

export const createZoomMeeting = params => async (dispatch, getState, sdk) => {
  dispatch(zoomMeetingRequest());
  try {
    const meeting = await createMeeting({ ...params });
    if (meeting?.status === 'success') dispatch(zoomMeetingCreated());
    else dispatch(zoomMeetingError());

    return meeting;
  } catch (e) {
    dispatch(zoomMeetingError());
  }
};

export const zohoAccessToken = (txId, param = {}) => async (dispatch, getState, sdk) => {
  try {
    const response = await getZohoAccessToken();

    const { data } = response;
    if (data.access_token) {
      Object.assign(data, {
        expiresAt: moment()
          .add(1, 'hour')
          .format('MM-DD-YYYY HH:mm:ss'),
      });
      const bodyParam = {
        zohoAccessToken: data,
        ...param,
      };
      dispatch(updateTransactionMetaData({ txId, bodyParam }));
      dispatch(fetchTransaction(txId));
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export const sendZohoDocument = params => async (dispatch, getState, sdk) => {
  try {
    const response = await sendDocument(params);
    return response;
  } catch (error) { }
};

export const fetchZohoDocument = params => async (dispatch, getState, sdk) => {
  const { access_token, request_id } = params;
  try {
    const response = await fetchDocument({ access_token, request_id });
    return response;
  } catch (error) { }
};

export const sendZohoReminder = params => async (dispatch, getState, sdk) => {
  const { access_token, request_id } = params;
  try {
    const response = await sendReminder({ access_token, request_id });
    if (response.data.status === 'success') {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) { }
};

export const fetchZohoTemplate = params => async (dispatch, getState, sdk) => {
  const { access_token, template_id } = params;
  try {
    const response = await fetchTemplate({ access_token, template_id });

    if (response.data.status === 'success') {
      return { success: true, data: response.data.templates };
    } else {
      return { success: false };
    }
  } catch (error) { }
};

export const deleteZohoTemplate = params => async (dispatch, getState, sdk) => {
  const { access_token, template_id } = params;
  try {
    const response = await deleteTemplate({ access_token, template_id });

    if (response.status === 'success') {
      return true;
    } else {
      return false;
    }
  } catch (error) { }
};

export const updateTransactionMetaData = params => async (dispatch, getState, sdk) => {
  try{
    const { txId, bodyParam } = params;

    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };
    const bodyParams = {
      id: txId,
      metadata: bodyParam,
    };
  
    const response = await updateMetadataRequest({
      bodyParams,
      queryParams,
    })
    if(response.status === 200){
      dispatch(addMarketplaceEntities(response));
      return response?.data?.data;
    }
    else throw new Error(response)
  }
  catch(error){
    return error;
  }
};

export const queryUserReviews = params => async (dispatch, getState, sdk) => {
  const { userId } = params;

  try {
    const response = await sdk.reviews.query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    });
    return response.data.data;
  } catch (error) { }
};

export const sendCSMInviteToFirmMemberToAccpetProposal = params => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(sendCsmInviteRequest());
    const { currentTransaction, protectedData } = params;
    const processName = currentTransaction?.id && currentTransaction?.attributes?.processName;
    const queryParams = {
      include: ['customer', 'provider'],
      expand: true,
    };
    if (processName === TRANSACTION_BRIEF_PROCESS) {
      const customerId =
        currentTransaction?.attributes?.lastTransition === TRANSITION_BRIEF_CREATE_PROPOSAL
          ? currentTransaction?.provider?.id?.uuid
          : currentTransaction?.customer?.id?.uuid;
      const { paymentFee = {}, paymentTerms = null } =
        currentTransaction?.id && currentTransaction?.attributes.metadata.proposal;
      const bookingData = {
        paymentFee,
        paymentTerms,
        shouldUsePaymentTerms: true,
        customerId,
      };

      const partnerId =
        currentTransaction?.attributes.processName === TRANSACTION_BRIEF_PROCESS
          ? currentTransaction.customer.id.uuid
          : currentTransaction.provider.id.uuid;
      const partnerProfile = await dispatch(fetchPartnerProfile(partnerId));
      const bodyParams = {
        processAlias: config.proposalProcessAlias,
        transition: TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR,
        params: {
          listingId: partnerProfile.id.uuid,
          protectedData: {
            ...protectedData,
          },
          metadata: {
            ...currentTransaction?.attributes.metadata,
          },
        },
      };
      // initiate privileged
      const { data: { data = {} } = {} } = await initiatePrivileged({
        bodyParams,
        bookingData,
        queryParams,
      });
      if (data) {
        await sdk.transactions.transition(
          {
            id: currentTransaction?.id,
            transition: TRANSITION_BRIEF_ACCEPT_PROPOSAL,

            params: {
              protectedData: {
                chainedTransactionId: data?.id?.uuid,
                chainedTransaction: data?.id?.uuid && JSON.stringify(data),
              },
            },
          },
          queryParams
        );
        dispatch(sendCsmInviteSuccess());
        return data;
      }
    }
    const { data: { data = {} } = {} } = await sdk.transactions.transition(
      {
        id: currentTransaction?.id,
        transition: TRANSITION_CSM_PRIVATE_BRIEF_INVITE,

        params: {},
      },
      queryParams
    );
    dispatch(sendCsmInviteSuccess());
    return data;
  } catch (error) {
    dispatch(sendCsmInviteError(storableError(error)));
  }
};
export const acceptProposalAfterCsmInvitation = params => async (dispatch, getState, sdk) => {
  dispatch(actionInProgress());
  try {
    const { currentTransaction } = params;
    const lastTransition = currentTransaction?.attributes?.lastTransition;

    const txData = {
      id: currentTransaction.id,
      transition:
        lastTransition === TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR
          ? TRANSITION_PROPOSAL_ACCEPT_AFTER_CSM_INVITE_OPERATOR
          : TRANSITION_ACCEPT_PROPOSAL_OPERATOR,
      params: {},
    };
    const response = await isdkTransactionsTransition({ txData });
    if (response) {
      dispatch(getIntegrationTxnData(currentTransaction?.id?.uuid));
      dispatch(actionSuccess());
      return response;
    }
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};
export const declineProposalAfterCsmInvitation = params => async (dispatch, getState, sdk) => {
  try {
    const { currentTransaction } = params;
    const lastTransition = currentTransaction?.attributes?.lastTransition;
    const txData = {
      id: currentTransaction.id,
      transition:
        lastTransition === TRANSITION_CSM_INVITE_ACCEPT_PROPOSAL_OPERATOR
          ? TRANSITION_PROPOSAL_DECLINE_AFTER_CSM_INVITE_OPERATOR
          : TRANSITION_PROPOSAL_DECLINE_CSM_INVITE_OPERATOR,
      params: {},
    };
    const response = await isdkTransactionsTransition({ txData });
    if (response) {
      dispatch(getIntegrationTxnData(currentTransaction?.id?.uuid));
      return response;
    }
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};

export const getAndRemoveNotifications = (currentUser, txId) => async (dispatch, getState, sdk) => {
  dispatch(notificationRequest());
  try {
    const { notifications = [] } = await getAndDeleteNotifications(currentUser.id.uuid);

    const filteredNotification = notifications[0]?.notificationSections?.filter(({ transactionId }) => transactionId === txId) ?? []
    dispatch(notificationSuccess(filteredNotification));
  } catch (error) {
    dispatch(notificationError(storableError(error)));
  }
};

export const handleFetchChatMessages = (chainedTxId) => async (dispatch, getState, sdk) => {
  try{
    const messages = await fetchTransactionChatMessages({chainedTransactionId: chainedTxId});
    return messages
  }catch(error){
    dispatch(fetchMessagesError(storableError(error)));
  }
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => async (dispatch, getState) => {
  try {
   
    const txId = new UUID(params.id);
    const state = getState().TransactionPage;
    const txRef = state.transactionRef;
    // const txRole = params.transactionRole;

    // In case a transaction reference is found from a previous
    // data load -> clear the state. Otherwise keep the non-null
    // and non-empty values which may have been set from a previous page.
    const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
    dispatch(setInitialValues(initialValues));
    const currentUser = await dispatch(fetchCurrentUser());
    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
      dispatch(fetchTransaction(txId)),
      // dispatch(getIntegrationTxnData(txId)),
      // dispatch(fetchMessages(txId, 1)),
      dispatch(fetchNextTransitions(txId)),
      dispatch(showFirmListing()),
      dispatch(getAndRemoveNotifications(currentUser, params.id)),
    ]);
  } catch (error) {
    dispatch(actionError(storableError(error)));
  }
};
